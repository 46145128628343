import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const LegalTemplate = props => {
  console.log(props)

  return (
    <Layout>
      <SEO title={props.data.shopifyShopPolicy.title} />
      <section className="section">
        <div className="container is-centered">
          <div className="columns">
            <div className="column is-8 is-offset-2">
              <h1 className="is-size-1 has-text-centered">
                {props.data.shopifyShopPolicy.title}
              </h1>
              <hr />
              <div className="box">
                <div className="content">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: props.data.shopifyShopPolicy.body,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query($id: String) {
    shopifyShopPolicy(id: { eq: $id }) {
      id
      title
      body
    }
  }
`

export default LegalTemplate
